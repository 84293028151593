const CATEGORY = {
  DELIVERY: 'delivery',
  PAYMENT: 'payment',
  ORDER: 'order',
  CUSTOMER: 'customer',
  MERCHANT: 'merchant',
  LOCATION: 'location',
};

const SUB_CATEGORY = {
  TRIGGERS: 'triggers',
};

// Cloud Functions
const COURIER = {
  MRSPEEDY: 'mrspeedy',
  LALAMOVE: 'lalamove',
  GRABEXPRESS: 'grabexpress',
  WHITELABEL: 'whitelabel',
};
const BASE = {
  MRSPEEDY: 'merchant-integration-delivery-mrSpeedy',
  LALAMOVE: 'merchant-integration-delivery-lalamove',
  PAYMONGO: 'payment-paymongo-createPaymentMethod',
};
const METHODS = {
  GETQUOTE: '-getQuote',
  CREATEORDER: '-createOrder',
  CANCELORDER: '-cancelOrder',
  GETCOURIER: '-getCourier',
};
const ORDER = {
  UPDATE: 'updateOrderStatusOnCall',
};

const DELIVERY = {
  CREATE: `${CATEGORY.DELIVERY}-create`,
  UPDATE_DELIVERY_ADDRESS: `${CATEGORY.DELIVERY}-create`,
  SPECIAL_REQUEST: {
    LALABAG: `LALABAG`,
  },
  whitelabel: {
    GET_QUOTE: `merchantWhiteLabelIntegGetQuote`,
    CREATE_ORDER: `merchantWhiteLabelIntegCreateOrder`,
    CANCEL_ORDER: `merchantWhiteLabelIntegCancelOrder`,
    GET_COURIER: `merchantWhiteLabelIntegGetCourier`,
  },
  mrspeedy: {
    GET_QUOTE: `${CATEGORY.DELIVERY}-${COURIER.MRSPEEDY}-getQuote`,
    CREATE_ORDER: `${CATEGORY.DELIVERY}-${COURIER.MRSPEEDY}-createOrder`,
    CANCEL_ORDER: `${CATEGORY.DELIVERY}-${COURIER.MRSPEEDY}-cancelOrder`,
    GET_COURIER: `${CATEGORY.DELIVERY}-${COURIER.MRSPEEDY}-getCourier`,
  },
  lalamove: {
    GET_QUOTE: `${CATEGORY.DELIVERY}-${COURIER.LALAMOVE}-getQuote`,
    CREATE_ORDER: `${CATEGORY.DELIVERY}-${COURIER.LALAMOVE}-createOrder`,
    GET_ORDER: `${CATEGORY.DELIVERY}-${COURIER.LALAMOVE}-getOrder`,
    CANCEL_ORDER: `${CATEGORY.DELIVERY}-${COURIER.LALAMOVE}-cancelOrder`,
    GET_COURIER: `${CATEGORY.DELIVERY}-${COURIER.LALAMOVE}-getCourier`,
    GET_LOCATION: `${CATEGORY.DELIVERY}-${COURIER.LALAMOVE}-getLocation`,
  },
  grabexpress: {
    GET_QUOTE: `${CATEGORY.DELIVERY}-${COURIER.GRABEXPRESS}-getQuote`,
    CREATE_ORDER: `${CATEGORY.DELIVERY}-${COURIER.GRABEXPRESS}-createOrder`,
    GET_ORDER: `${CATEGORY.DELIVERY}-${COURIER.GRABEXPRESS}-getOrder`,
    CANCEL_ORDER: `${CATEGORY.DELIVERY}-${COURIER.GRABEXPRESS}-cancelOrder`,
  },
};

const PAYMONGO = {
  CREATE_PAYMENT_METHOD: `${BASE.PAYMONGO}`,
};

const PAYMENT = {
  CREATE: `${CATEGORY.PAYMENT}-${SUB_CATEGORY.TRIGGERS}-create`,
  UPDATE: `${CATEGORY.PAYMENT}-${SUB_CATEGORY.TRIGGERS}-updateStatus`,
};

const LOCATION = {
  UPDATE: `${CATEGORY.LOCATION}-update`,
};

export const INTEGRATION = {
  ORDER,
  DELIVERY,
  PAYMONGO,
  COURIER,
  PAYMENT,
  LOCATION,
};
