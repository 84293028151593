import { Injectable, OnInit } from '@angular/core';
import * as firebase from 'firebase/app';
import { RemoteConfigDefaults } from './remote.config.default';

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigService implements OnInit {
  remoteConfig = firebase.remoteConfig();

  constructor(private rcDefault: RemoteConfigDefaults) {
    this.remoteConfig.settings = {
      fetchTimeoutMillis: 3600000,
      minimumFetchIntervalMillis: 3600,
    };
    this.remoteConfig.defaultConfig = this.rcDefault.getDefaultConfigs();
  }

  ngOnInit(): void {}

  get config() {
    return this.remoteConfig;
  }

  fetch() {
    return this.config.fetch();
  }

  activate() {
    return this.config.activate();
  }

  fetchAndActivate() {
    return this.config.fetchAndActivate();
  }

  ensureInitialized() {
    return this.config.ensureInitialized();
  }

  // returns only value
  getString(id: string) {
    return this.config.getString(id);
  }

  // returns value & source
  getValue(id: string) {
    return this.config.getValue(id);
  }
}
