import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GetTimePipe } from './get-time.pipe';
import { ReplacePipe } from './replace-string.pipe';
import { FilterPipe } from './filter.pipe';
import { ItemQuantityPipe } from './item-quantity.pipe';
import { TimeInHMSPipe } from './delivery-duration.pipe';

@NgModule({
  declarations: [ReplacePipe, GetTimePipe, FilterPipe, ItemQuantityPipe, TimeInHMSPipe],
  imports: [CommonModule],
  exports: [ReplacePipe, GetTimePipe, FilterPipe, ItemQuantityPipe, TimeInHMSPipe],
})
export class PipeModule {}
