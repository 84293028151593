import { DxVectorMapModule } from 'devextreme-angular';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

// Angular
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Ngx-Bootstrap
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCheckboxModule } from '@angular/material/checkbox';

// Full Calendar
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridDayPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { VectorMapComponent } from './vector-map/vector-map.component';
import { PictureUploadComponent } from './picture-upload/picture-upload.component';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { RtlNavbarComponent } from './rtl-navbar/rtl-navbar.component';
import { RtlSidebarComponent } from './rtl-sidebar/rtl-sidebar.component';
import { FixedPluginComponent } from './fixed-plugin/fixed-plugin.component';
import { EventCalendarComponent } from './event-calendar/event-calendar.component';
import { DetailsFieldComponent } from './details-field/details-field.component';

import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { SearchPlaceComponent } from './serach-place/search-place';

import { OrderCardComponent } from './order-card/order-card.component';

import { PipeModule } from 'src/app/core/pipes/pipe.module';

FullCalendarModule.registerPlugins([
  // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridDayPlugin,
  momentTimezonePlugin,
]);

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    JwBootstrapSwitchNg2Module,
    DxVectorMapModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule,
    TooltipModule.forRoot(),
    FullCalendarModule,
    MatTabsModule,
    MatCheckboxModule,
    PipeModule,
  ],
  declarations: [
    FooterComponent,
    VectorMapComponent,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    RtlNavbarComponent,
    RtlSidebarComponent,
    SearchPlaceComponent,
    FixedPluginComponent,
    LoadingSpinnerComponent,
    EventCalendarComponent,
    DetailsFieldComponent,
    OrderCardComponent,
  ],
  exports: [
    FooterComponent,
    VectorMapComponent,
    NavbarComponent,
    SidebarComponent,
    PictureUploadComponent,
    AuthNavbarComponent,
    RtlNavbarComponent,
    RtlSidebarComponent,
    SearchPlaceComponent,
    FixedPluginComponent,
    LoadingSpinnerComponent,
    EventCalendarComponent,
    DetailsFieldComponent,
    OrderCardComponent,
  ],
  providers: [DatePipe],
})
export class ComponentsModule {}
