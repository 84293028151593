export const MODAL = {
  pinLocation: {
    success: 'pinLocation.success',
    updating: 'pinLocation.updating',
    error: 'pinLocation.error',
    check: 'pinLocation.check',
  },
  updateAddress: {
    success: 'updateAddress.success',
    updating: 'updateAddress.updating',
    error: 'updateAddress.error',
    check: 'updateAddress.check',
  },
  agentUpdate: {
    success: 'agentUpdate.success',
    updating: 'agentUpdate.updating',
    error: 'agentUpdate.error',
  },
  orderUpdate: {
    success: 'orderUpdate.success',
    updating: 'orderUpdate.updating',
    error: 'orderUpdate.error',
  },
  deliveryCancel: {
    success: 'deliveryCancel.success',
    updating: 'deliveryCancel.updating',
    error: 'deliveryCancel.error',
  },
  paymentUpdate: {
    success: 'paymentUpdate.success',
    updating: 'paymentUpdate.updating',
    error: 'paymentUpdate.error',
    alert: 'paymentUpdate.alert',
  },
  assignPlatform: {
    successTitle: 'assignPlatform.success.title',
    successText: 'assignPlatform.success.text',
    updatingTitle: 'assignPlatform.updating.title',
    updatingText: 'assignPlatform.updating.text',
    error: 'assignPlatform.error',
  },
  createBranch: {
    success: 'createBranch.success',
    updating: 'createBranch.updating',
    error: 'createBranch.error',
  },
  removeBranch: {
    success: 'removeBranch.success',
    updating: 'removeBranch.updating',
    error: 'removeBranch.error',
  },
  updateBranch: {
    success: 'updateBranch.success',
    updating: 'updateBranch.updating',
    error: 'updateBranch.error',
  },
  createManager: {
    success: 'createManager.success',
    updating: 'createManager.updating',
    error: 'createManager.error',
  },
  removeManager: {
    success: 'removeManager.success',
    updating: 'removeManager.updating',
    error: 'removeManager.error',
  },
  updateManager: {
    success: 'updateManager.success',
    updating: 'updateManager.updating',
    error: 'updateManager.error',
  },
  confirmRemoveBranch: 'confirmRemoveBranch',
  confirmUpdateBranch: 'confirmUpdateBranch',
  confirmUpdateManager: 'confirmUpdateManager',
  confirmRemoveManager: 'confirmRemoveManager',
  confirmRemoveAgent: 'confirmRemoveAgent',
  confirmUpdateAgent: 'confirmUpdateAgent',
  userDevice: {
    alert: 'userDevice.alert',
    text: 'userDevice.text',
  },
};
