import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any, searchText: any): any {
    if (!searchText) {
      return items;
    }
    return items.filter((item) => this.matchValue(item, searchText));
  }

  matchValue(item, items) {
    return Object.keys(item)
      .map((key) => {
        return new RegExp(items, 'gi').test(item[key]);
      })
      .some((result) => result);
  }
}
