import { createSelector } from '@ngrx/store';
import { StoreRootState } from './../state.reducers';
import { Merchant } from 'src/app/models/data/merchant.model';
import { State } from './auth.reducer';

export const selectState = (state: StoreRootState) => state.auth;

export const selectMerchantSelectedBranch = createSelector(selectState, (auth: State) => auth.merchantSelectedBranch);
export const selectSelectedBranch = createSelector(selectState, (auth: State) => auth.selectedBranch);

export const selectUser = createSelector(selectState, (auth: State) => auth.user);

export const selectHq = createSelector(selectUser, (user: Merchant) => user.hq);

export const selectUserDefaults = createSelector(selectUser, (user: Merchant) => user.defaults);

export const selectUserProfile = createSelector(selectUser, (user: Merchant) => user.profile);

export const selectUserBranches = createSelector(selectUser, (user: Merchant) =>
  user ? Object.values(user?.branches).map(Object) : user?.branches
);

export const selectUserRoles = createSelector(selectUser, (user: Merchant) => user.roles);

export const selectUserRole = createSelector(selectUser, (user: Merchant) => user.role);

export const selectMerchantUid = createSelector(selectUser, (user: Merchant) => user.uid);
