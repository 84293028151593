import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { CountdownModule } from 'ngx-countdown';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { AppRoutingModule } from './app-routing.module';
import { AppStateModule } from './state/state.module';
import { ComponentsModule } from './components/components.module';
import { RtlLayoutComponent } from './layouts/rtl-layout/rtl-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipeModule } from './core/pipes/pipe.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, RtlLayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    CountdownModule,
    RouterModule,
    AppRoutingModule,
    AppStateModule,
    ToastrModule.forRoot(),
    ComponentsModule,
    NgbModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    PipeModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
