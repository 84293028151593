import { Injectable } from '@angular/core';

import { CloudFunctionsService } from 'src/app/services/firebase/cloud.functions.services';

import { OrderDetails, RealTimeOrder } from 'src/app/models/data/order.model';

import { INTEGRATION } from 'src/app/core/constants/integration.constants';
import {
  GrabExpressDeliveryRequest,
  GrabExpressDeliveryResponse,
  GrabExpressQuotesRequest,
  Location,
  GrabExpressQuotesResponse,
} from '@models/integration/grab.model';
import { transformNumberForGrabExpress } from 'src/app/core/utils/number';

@Injectable({
  providedIn: 'root',
})
export class GrabExpressService {
  private grabExpressFunctions = this.cloudFunction.get(INTEGRATION.COURIER.GRABEXPRESS);

  constructor(private cloudFunction: CloudFunctionsService) {}

  async quote(orders: OrderDetails[], merchantId: string) {
    try {
      const res = await this.grabExpressFunctions.getQuote({ orders, merchantId });
      const response: GrabExpressQuotesResponse = res?.data?.data;
      const price = response.quotes[0].amount;
      response['loading'] = false;
      response['price'] = Number(price).toFixed(2);
      return response;
    } catch (error) {
      const response = error;
      response['loading'] = true;
      response['price'] = 0;
      return response;
    }
  }

  async assign(orders: OrderDetails[], platformPricing, merchantId: string) {
    try {
      const res = await this.grabExpressFunctions.createOrder({ orders, merchantId });
      const response: GrabExpressDeliveryResponse = res?.data;
      response['courierOrderId'] = response?.deliveryID;
      response['deliveryFee'] = response?.quote?.amount;
      return response;
    } catch (error) {
      return error;
    }
  }

  async cancel(branchId: string, merchantId: string, orderDetails: RealTimeOrder, courierOrderId: string) {
    try {
      const res = await this.grabExpressFunctions.cancelOrder({
        branchId,
        merchantId,
        orderDetails,
        id: courierOrderId,
      });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }

  async getOrder(branchId: string, merchantId: string, orderDetails: RealTimeOrder, courierOrderId: string) {
    try {
      const res = await this.grabExpressFunctions.getOrder({
        branchId,
        merchantId,
        orderDetails,
        id: courierOrderId,
      });
      const response = res?.data?.data;
      return response;
    } catch (error) {
      return error;
    }
  }
}
