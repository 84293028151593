import { timeZone } from '../types/timezone.type';

export const Default = {
  lang: 'en-us',
  format: 'YYYY/M/D',
  timeZone: 'Asia/Manila' as timeZone,
};

export const Format = {
  eventCalendarFormat: 'YYYY MM DD',
  '24HrFormat': 'hh:mm a',
  longFormat: 'MMMM Do YYYY, h:mm a',
  tootlTip: 'dddd, MMMM D, h:mm a',
};
