export const DEFAULT = {
  PUBLIC_STORAGE: 'https://storage.googleapis.com/zipub/v2',
  MARKERS: {
    MERCHANT: 'marker-merchant.png',
    CUSTOMER: 'marker-customer.png',
    AGENT: 'marker-agent.png',
    MARKER_CLUSTER: 'agent-cluster-marker',
  },
  THIRD_PARTY_MARKERS: {
    lalamove: 'marker-lalamove.png',
    mrspeedy: 'marker-mrspeedy.png',
    grab: 'marker-grab.png',
    transportify: 'marker-transportify',
    generic: 'marker-generic.png',
  },
};

export const ENVIRONMENTS = {
  DEV: 'DEV',
  STAGE: 'STAGE',
  PROD: 'PROD',
};

export const SUB_DOMAINS = {
  DEV: 'dev.',
  STAGE: 'stage.',
  PROD: '', // prod has no suburl
};

export const BASE_URL = {
  ZIPELI: 'zipeli.com',
};

export const APPS_URL = {
  CUSTOMER: {
    BASE: `customer.${BASE_URL.ZIPELI}`,
    TRACK: `track?id`,
    GET_TRACKING_URL: (subDomain: string, id: string) =>
      `https://${subDomain}${APPS_URL.CUSTOMER.BASE}/${APPS_URL.CUSTOMER.TRACK}=${id}`,
  },
  MERCHANT: `merchant.${BASE_URL.ZIPELI}`,
  MAPS: (address: string) => `http://maps.google.com/?q=${address}`,
};

export const BUX_URL = {
  DEV: 'https://bux.ph/test/checkout/',
  STAGE: 'https://bux.ph/test/checkout/',
  PROD: 'https://bux.ph/checkout/',
};

export const FIELD_TYPE = {
  date: 'date',
  string: 'string',
  badge: 'badge',
  payment: 'payment',
};

export const DEFAULT_CONNECTION_TYPE = '4g';
export const DEFAULT_CONNECTION_SPEED = '0.5';

export const MERCHANT = 'Merchant';
export const CUSTOMER = 'Customer';

export const TIME_IN_SECONDS = {
  HOUR_IN_SECONDS: 3600,
  MINUTE_IN_SECONDS: 60,
};

export const BACKGROUND_COLOR = {
  SUCCESS: '#6faa63',
  ZIP_PRIMARY: '#07b0dc',
};
