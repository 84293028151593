import { mergeMap, map, catchError, first, concatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
// Service
import { DeliveryService } from 'src/app/services/integration/delivery/delivery.service';
import { FirestoreService } from 'src/app/services/firebase/firestore.service';
// State
import { Store, select } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as fromOperation from 'src/app/state/dashboard/operation/operation.selector';
import * as fromDelivery from 'src/app/state/dashboard/delivery/delivery.selector';
import * as fromAuth from 'src/app/state/auth/auth.selector';
import * as IntegrationActions from './integration.actions';
import * as fromIntegration from './integration.selector';
// Models
import { Platform } from 'src/app/models/data/platform.model';
import { OrderDetails } from 'src/app/models/data/order.model';
import { RTDBService } from 'src/app/services/firebase/rtdb.service';

@Injectable()
export class IntegrationEffects {
  getDeliveryPricing$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.GET_DELIVERY_PRICING),
        mergeMap(({ orderDetails, merchantOwnerUid }) =>
          this.deliveryService.generateQuotes(orderDetails, merchantOwnerUid).pipe(
            map((pricing) => {
              return IntegrationActions.setDeliveryPricing({
                pricing,
              });
            }),
            catchError((err) =>
              of(
                IntegrationActions.error({
                  error: err.message,
                })
              )
            )
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  cancelDeliveryOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.CANCEL_DELIVERY_ORDER),
        mergeMap(({ platform, branchId, merchantId, orderDetails }) =>
          this.deliveryService.cancelOrder(platform, branchId, merchantId, orderDetails).pipe(
            map((result) => {
              return IntegrationActions.cancelDeliveryOrderSuccess({ result: result });
            }),
            catchError((err) => of(IntegrationActions.cancelDeliveryOrderError({ error: err })))
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  // reassignDeliveryOrder$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(IntegrationActions.REASSIGN_DELIVERY_ORDER),
  //     mergeMap(({ platform, newPlatform, branchId, merchantId, orderId, orderDetails }) =>
  //       this.deliveryService.cancelOrder(platform, branchId, merchantId, orderDetails).pipe(
  //         concatMap((result) => {
  //           return [
  //             IntegrationActions.cancelDeliveryOrderSuccess({ result: result }),
  //             IntegrationActions.assignDeliveryPlatform({
  //               orderId,
  //               platform: newPlatform,
  //               merchantOwnerUid: merchantId,
  //             }),
  //           ];
  //         }),
  //         catchError((err) => of(IntegrationActions.cancelDeliveryOrderError({ error: err })))
  //       )
  //     )
  //   )
  // );

  assignDeliveryPlatform$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.ASSIGN_DELIVERY_PLATFORM),
        mergeMap(({ orders, platform, merchantOwnerUid }) =>
          this.store.pipe(
            select(fromIntegration.selectDeliveryPricing),
            first(),
            map((pricing) => {
              const _platform = platform as Platform;
              const platformPricing = pricing[_platform.id];
              return [orders, platform, platformPricing, merchantOwnerUid];
            })
          )
        ),
        mergeMap(([orders, platform, platformPricing, merchantOwnerUid]) => {
          const _platform = platform as unknown as Platform;
          const _orderDetails = orders as OrderDetails[];
          return this.deliveryService.assignPlatform(_platform, _orderDetails, platformPricing, merchantOwnerUid).pipe(
            map((response) => {
              return IntegrationActions.success({ response });
            }),
            catchError((err) => of(IntegrationActions.error({ error: err })))
          );
        })
      ),
    {
      dispatch: true,
    }
  );

  getWhiteLabelAgents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(IntegrationActions.GET_WHITELABEL_AGENTS),
      mergeMap(({ branchId }) =>
        this.rtdbService.getWhiteLabelAgents(branchId).pipe(
          map((agents) => {
            return IntegrationActions.setWhiteLabelAgents({ agents });
          }),
          catchError((err) =>
            of(
              IntegrationActions.error({
                error: err.message,
              })
            )
          )
        )
      )
    )
  );

  assignWhiteLabelDeliveryPlatformInDelivery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY),
        mergeMap(({ orders, agentId, merchantOwnerUid }) =>
          this.store.pipe(
            first(),
            map(() => ({ orders, agentId, merchantOwnerUid }))
          )
        ),
        mergeMap(({ orders, agentId, merchantOwnerUid }) =>
          this.store.pipe(
            select(fromAuth.selectSelectedBranch),
            first(),
            map((branch) => {
              const branchId = branch.id;
              return { orders, agentId, branchId, merchantOwnerUid };
            })
          )
        ),
        mergeMap(({ orders, agentId, branchId, merchantOwnerUid }) => {
          const _agentId = agentId as string;
          const _orderDetails = orders as OrderDetails[];
          const _branchId = branchId as string;
          return this.deliveryService
            .assignWhiteLabelPlatform(_agentId, _branchId, _orderDetails, merchantOwnerUid)
            .pipe(
              map((message) => {
                return IntegrationActions.setWhiteLabelDeliveryDetails({
                  message,
                });
              })
            );
        }),
        catchError((err) =>
          of(
            IntegrationActions.error({
              error: err.message,
            })
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  multiDropOffAssignWhiteLabelDeliveryPlatformInDelivery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.MULTI_DROPOFF_ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY),
        mergeMap(({ agentId, merchantOwnerUid }) =>
          this.store.pipe(
            select(fromDelivery.selectSelectedRealTimeOrder),
            first(),
            map((orderDetails) => [agentId, orderDetails, merchantOwnerUid])
          )
        ),
        mergeMap(([agentId, orderDetails, merchantOwnerUid]) =>
          this.store.pipe(
            select(fromAuth.selectSelectedBranch),
            first(),
            map((branch) => {
              const branchId = branch.id;
              return [agentId, branchId, orderDetails, merchantOwnerUid];
            })
          )
        ),
        mergeMap(([agentId, branchId, orderDetails, merchantOwnerUid]) => {
          const _agentId = agentId as string;
          const _orderDetails = orderDetails as OrderDetails[];
          const _branchId = branchId as string;
          return this.deliveryService
            .assignWhiteLabelPlatform(_agentId, _branchId, _orderDetails, merchantOwnerUid)
            .pipe(
              map((message) => {
                return IntegrationActions.setWhiteLabelDeliveryDetails({
                  message,
                });
              })
            );
        }),
        catchError((err) =>
          of(
            IntegrationActions.error({
              error: err.message,
            })
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  // autoAssignWhiteLabelDeliveryPlatformInDelivery$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(IntegrationActions.AUTO_ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_DELIVERY),
  //       mergeMap(({ agentId, merchantOwnerUid }) =>
  //         this.store.pipe(
  //           select(fromDelivery.selectAssignQueuedOrder),
  //           first(),
  //           map((orderDetails) => [agentId, orderDetails, merchantOwnerUid])
  //         )
  //       ),
  //       mergeMap(([agentId, orderDetails, merchantOwnerUid]) =>
  //         this.store.pipe(
  //           select(fromAuth.selectSelectedBranch),
  //           first(),
  //           map((branch) => {
  //             const branchId = branch.id;
  //             return [agentId, branchId, orderDetails, merchantOwnerUid];
  //           })
  //         )
  //       ),
  //       mergeMap(([agentId, branchId, orderDetails, merchantOwnerUid]) => {
  //         const _agentId = agentId as string;
  //         const _orderDetails = orderDetails as OrderDetails;
  //         const _branchId = branchId as string;
  //         return this.deliveryService
  //           .assignWhiteLabelPlatform(_agentId, _branchId, _orderDetails[], merchantOwnerUid)
  //           .pipe(
  //             map((message) => {
  //               return IntegrationActions.setWhiteLabelAutoAllocationDeliveryDetails({
  //                 message,dorect
  //           );
  //       }),
  //       catchError((err) =>
  //         of(
  //           IntegrationActions.error({
  //             error: err.message,dorect
  //     dispatch: true,
  //   }
  // );

  // assignWhiteLabelDeliveryPlatformInOperation$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(IntegrationActions.ASSIGN_WHITELABEL_DELIVERY_PLATFORM_IN_OPERATION),
  //       mergeMap(({ agentId, merchantOwnerUid }) =>
  //         this.store.pipe(
  //           select(fromOperation.selectSelectedOrderDetails),
  //           first(),
  //           map((orderDetails) => [agentId, orderDetails, merchantOwnerUid])
  //         )
  //       ),
  //       mergeMap(([agentId, orderDetails, merchantOwnerUid]) =>
  //         this.store.pipe(
  //           select(fromAuth.selectSelectedBranch),
  //           first(),
  //           map((branch) => {
  //             const branchId = branch.id;
  //             return [agentId, branchId, orderDetails, merchantOwnerUid];
  //           })
  //         )
  //       ),
  //       mergeMap(([agentId, branchId, orderDetails, merchantOwnerUid]) => {
  //         const _agentId = agentId as string;
  //         const _orderDetails = orderDetails as OrderDetails;
  //         const _branchId = branchId as string;
  //         return this.deliveryService
  //           .assignWhiteLabelPlatform(_agentId, _branchId, _orderDetails, merchantOwnerUid)
  //           .pipe(
  //             map((message) => {
  //               return IntegrationActions.setWhiteLabelDeliveryDetails({
  //                 message,
  //               });
  //             })
  //           );
  //       }),
  //       catchError((err) =>
  //         of(
  //           IntegrationActions.error({
  //             error: err.message,
  //           })
  //         )
  //       )
  //     ),
  //   {
  //     dispatch: true,
  //   }
  // );

  setDeliveryDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.SET_DELIVERY_DETAILS),
        mergeMap(({ deliveryOrder, orderId, branchId, merchantOwnerUid }) =>
          this.deliveryService.createDelivery(deliveryOrder, orderId, branchId, merchantOwnerUid).pipe(
            map(() => {
              return IntegrationActions.setDeliveryDetailsSuccess();
            }),
            catchError((err) =>
              of(
                IntegrationActions.error({
                  error: err.message,
                })
              )
            )
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  removeDeliveryOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.REMOVE_DELIVERY_ORDER),
        mergeMap(({ orderId }) =>
          this.store.pipe(
            select(fromAuth.selectSelectedBranch),
            first(),
            map((branch) => {
              const branchId = branch.id;
              return [orderId, branchId];
            })
          )
        ),
        mergeMap(([orderId, branchId]) =>
          this.fsService.getOrderDetails(orderId, branchId).pipe(
            map((orderDetails) => {
              return [branchId, orderDetails];
            })
          )
        ),
        mergeMap(([branchId, orderDetails]) =>
          this.rtdbService
            .removeOrder({
              branchId,
              orderDetails,
            })
            .pipe(
              map((response) => {
                return IntegrationActions.success({ response });
              }),
              catchError((err) =>
                of(
                  IntegrationActions.error({
                    error: err.message,
                  })
                )
              )
            )
        )
      ),
    {
      dispatch: true,
    }
  );

  updateOrderDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.UPDATE_ORDER_DETAILS),
        mergeMap(({ branchId, orderDetails, deliveryOrder }) =>
          this.rtdbService
            .updateOrderDetails({
              branchId,
              orderDetails,
              deliveryOrder,
            })
            .pipe(
              map(() => IntegrationActions.updateOrderDetailsSuccess()),
              catchError((err) =>
                of(
                  IntegrationActions.error({
                    error: err.message,
                  })
                )
              )
            )
        )
      ),
    {
      dispatch: true,
    }
  );

  getOrderDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.GET_ORDER_DETAILS),
        mergeMap(({ platform, branchId, merchantId, orderDetails }) =>
          this.deliveryService.getOrder(platform, branchId, merchantId, orderDetails).pipe(
            map((response) => {
              return IntegrationActions.success(response);
            }),
            catchError((err) =>
              of(
                IntegrationActions.error({
                  error: err.message,
                })
              )
            )
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  getDeliveryCourier$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.GET_DELIVERY_COURIER),
        mergeMap(({ platform, branchId, merchantId, orderDetails }) =>
          this.deliveryService.getCourier(platform, branchId, merchantId, orderDetails).pipe(
            map((response) => {
              return IntegrationActions.success(response);
            }),
            catchError((err) =>
              of(
                IntegrationActions.error({
                  error: err.message,
                })
              )
            )
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  getDeliveryLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(IntegrationActions.GET_DELIVERY_LOCATION),
        mergeMap(({ platform, branchId, merchantId, orderDetails }) =>
          this.deliveryService.getLocation(platform, branchId, merchantId, orderDetails).pipe(
            map((response) => {
              return IntegrationActions.success(response);
            }),
            catchError((err) =>
              of(
                IntegrationActions.error({
                  error: err.message,
                })
              )
            )
          )
        )
      ),
    {
      dispatch: true,
    }
  );

  constructor(
    private actions$: Actions,
    private deliveryService: DeliveryService,
    private rtdbService: RTDBService,
    private fsService: FirestoreService,
    private store: Store<StoreRootState>
  ) {}
}
