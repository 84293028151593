import { Location, DatePipe } from '@angular/common';
import { Component, OnInit, ElementRef, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as _ from 'lodash';

import { AuthService } from '@firebase/auth.service';
import { IDUtilService } from 'src/app/services/utils/id.util.service';
import { MapService } from 'src/app/services/google/map.service';
import { NAVROUTES } from '../sidebar/sidebar.component';
import { TranslateService } from '@ngx-translate/core';

import { Task, Delivery, Pickup } from '@models/data/task.model';
import { BasicUser } from '@models/data/user.model';

import { Store } from '@ngrx/store';
import * as RouterSelector from '@state/state.selector';
import { StoreRootState } from '@state/state.reducers';

import { DASHBOARD } from '@constants/dashboard.constants';
import * as fromDelivery from '@state/dashboard/delivery/delivery.selector';
import * as Time from 'src/app/core/utils/time';
import * as DeliveryActions from '@state/dashboard/delivery/delivery.actions';
import * as AuthActions from '@state/auth/auth.actions';
import * as fromSettings from '@state/settings/settings.selector';

import { GlobalDataService } from '@shared/global.shared.service';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { Merchant } from '@models/v2/merchant.model';
import { timeZone } from '../../core/types/timezone.type';
import { getDateinTimeZone } from '../../core/utils/time';
import { TimeService } from '../../services/shared/time.shared.service';
import { selectSelectedDateFilter } from '../../state/dashboard/delivery/delivery.selector';
import { Format, Default } from '../../core/constants/time.constants';

const misc: any = {
  sidebar_mini_active: true,
};

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit, OnDestroy {
  showDashboardButton = false;
  showAllBranchesSelector = false;
  public today = this.time.getCurrent();
  public tomorrow = new Date(this.time.getTomorrowDate(this.today));
  private previousDateFilter: Date;
  public selectedDateFilterDisplay: Date;
  selectedDateFilter: any;
  private todayStr = DASHBOARD.ORDERS.FILTERS.TODAY;
  private yesterdayStr = DASHBOARD.ORDERS.FILTERS.YESTERDAY;
  public dateFilters = {
    selected: this.todayStr,
    [this.todayStr]: Time.moment$().format(Time.defaultFormat),
    [this.yesterdayStr]: Time.moment$().subtract(1, 'd').format(Time.defaultFormat),
  };

  branchesData = [];
  selectedBranch = [];
  merchantSelectedBranch = [];

  // forms
  focus = {
    delivery: {
      mobile: false,
      email: false,
      name: false,
      address: false,
      notes: false,
      dateBefore: false,
      items: false,
    },
    pickup: {
      dateBefore: false,
    },
  };

  validationMessages: any;

  // forms
  public searchPlace = '';
  public places = [];

  private listTitles: any[];
  location: Location;
  public state = true;
  public dashboardColor = true;

  private navbarToggler: any;
  public isCollapsed = true;

  taskForm: FormGroup;
  pickupForm: any;
  texts: any;
  $layer: any;
  allBranchesData: any;
  user: Merchant;

  constructor(
    private ngZone: NgZone,
    location: Location,
    private element: ElementRef,
    private router: Router,
    public toastr: ToastrService,
    public auth: AuthService,
    private fb: FormBuilder,
    private idUtil: IDUtilService,
    public datepipe: DatePipe,
    private mapService: MapService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private store: Store<StoreRootState>,
    private global: GlobalDataService,
    private time: TimeService
  ) {
    this.location = location;
    this.auth.userDoc$.subscribe((data) => {
      if (data) {
        // merchant data
        this.pickupForm = {
          mobile: data.contact,
          email: data.email,
          name: data.displayName,
          address: data.address,
        };
      }
      this.cdr.detectChanges();
    });
    this.taskForm = this.fb.group({
      delivery: this.fb.group({
        mobile: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        name: ['', Validators.required],
        address: ['', Validators.required],
        notes: ['', Validators.required],
        dateBefore: ['', Validators.required],
        items: ['', Validators.required],
      }),
      pickup: this.fb.group({
        dateBefore: ['', Validators.required],
      }),
    });
  }

  get delivery() {
    return this.taskForm.get('delivery');
  }

  get pickup() {
    return this.taskForm.get('pickup');
  }

  get dvMobile() {
    return this.delivery.get('mobile');
  }
  get dvEmail() {
    return this.delivery.get('email');
  }
  get dvName() {
    return this.delivery.get('name');
  }
  get dvAddress() {
    return this.delivery.get('address');
  }
  get dvNotes() {
    return this.delivery.get('notes');
  }
  get dvDateBefore() {
    return this.delivery.get('dateBefore');
  }
  get puDateBefore() {
    return this.pickup.get('dateBefore');
  }
  get puDvItems() {
    return this.delivery.get('items');
  }

  ngOnInit() {
    window.addEventListener('resize', this.updateColor);
    this.listTitles = NAVROUTES.filter((listTitle) => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    this.navbarToggler = navbar.getElementsByClassName('navbar-toggler');

    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
    this.initializeTranslation();
    this.initializeValidationMessages();

    this.store.select(RouterSelector.selectUrl).subscribe((route: any) => {
      this.showDashboardButtons(route);
    });

    this.store.select(fromDelivery.selectSelectedDateFilter).subscribe((dateFilter) => {
      if (this.selectedDateFilter !== dateFilter) {
        this.selectedDateFilterDisplay = Time.moment$(new Date(dateFilter)).toDate();
        this.selectedDateFilter = dateFilter;
      }
    });

    this.global.user$.subscribe((userData: Merchant) => {
      this.allBranchesData = [];
      if (userData?.role === DASHBOARD.ROLES.OWNER) {
        this.user = userData;
        this.allBranchesData.push({ id: userData.uid, itemName: this.texts?.allBranches });
      }
    });

    this.global.handledBranchesData$.subscribe((branchesData) => {
      this.branchesData = [];
      if (branchesData) {
        const branchesArr = Object.keys(branchesData).map((key) => [String(key), branchesData[key]]);
        branchesArr.map((branch) => {
          this.branchesData.push({ id: branch[1].id, itemName: branch[1].name });
          this.allBranchesData.push({ id: branch[1].id, itemName: branch[1].name });
        });
      }
    });

    this.global.merchantSelectedBranch$.subscribe((selectedBranch: any) => {
      this.merchantSelectedBranch = [];
      if (selectedBranch) {
        this.merchantSelectedBranch = [{ id: selectedBranch?.id, itemName: selectedBranch?.name }];
      } else {
        this.merchantSelectedBranch = [{ id: this.user?.uid, itemName: this.texts?.allBranches }];
      }
    });

    this.global.selectedBranch$.subscribe((selectedBranch: any) => {
      this.selectedBranch = [];
      if (selectedBranch) {
        this.selectedBranch = [{ id: selectedBranch.id, itemName: selectedBranch.name }];
      }
    });
  }

  onOwnerBranchFilterChange(branch: any) {
    const selectedBranch = {
      id: branch.id,
      name: branch.itemName,
    };
    this.global.changeMerchantSelectedBranch(selectedBranch);
  }

  onBranchFilterChange(branch: any) {
    this.global.changeSelectedBranch(branch.id);
  }

  initializeTranslation() {
    this.translate.get('NAV').subscribe((texts) => {
      this.texts = texts;
    });
  }

  initializeValidationMessages() {
    this.validationMessages = {
      delivery: {
        mobile: [{ type: 'required', message: `${this.texts.validationMessages.mobile}` }],
        email: [{ type: 'required', message: `${this.texts.validationMessages.email}` }],
        name: [{ type: 'required', message: `${this.texts.validationMessages.name}` }],
        address: [{ type: 'required', message: `${this.texts.validationMessages.address}` }],
        dateBefore: [{ type: 'required', message: `${this.texts.validationMessages.deliveryDateBefore}` }],
        items: [{ type: 'required', message: `${this.texts.validationMessages.items}` }],
      },
      pickup: {
        dateBefore: [{ type: 'required', message: `${this.texts.validationMessages.pickupDateBefore}` }],
      },
    };
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    const navbar = document.getElementsByClassName('navbar')[0];
    if (window.innerWidth < 993 && !this.isCollapsed && this.showDashboardButton) {
      navbar.classList.add('bg-white');
      navbar.classList.remove('navbar-transparent');
    } else {
      if (this.showDashboardButton) {
        navbar.classList.remove('bg-white');
        navbar.classList.add('navbar-transparent');
      }
    }
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
      // this.showSidebarMessage("Sidebar mini deactivated...");
    } else {
      body.classList.add('sidebar-mini');
      // this.showSidebarMessage("Sidebar mini activated...");
      misc.sidebar_mini_active = true;
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  showSidebarMessage(message) {
    this.toastr.show('<span data-notify="icon" class="tim-icons icon-bell-55"></span>', message, {
      timeOut: 4000,
      closeButton: true,
      enableHtml: true,
      toastClass: 'alert alert-danger alert-with-icon',
      positionClass: 'toast-top-right',
    });
  }
  ngOnDestroy() {
    window.removeEventListener('resize', this.updateColor);
  }
  getTitle() {
    let title = this.location.prepareExternalUrl(this.location.path());
    if (title.charAt(0) === '#') {
      title = title.slice(1);
    }

    for (let item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === title) {
        return this.listTitles[item].title;
      }
    }
    return 'Dashboard';
  }

  sidebarToggle() {
    const navOpen = document.getElementsByClassName('nav-open')[0];
    navOpen ? this.sidebarClose() : this.sidebarOpen();
  }

  sidebarOpen() {
    const toggleButton = this.navbarToggler[0];
    const body = <HTMLElement>document.getElementsByTagName('body')[0];
    const html = document.getElementsByTagName('html')[0];

    setTimeout(function () {
      if (toggleButton) {
        toggleButton.classList.add('toggled');
      }
    }, 500);

    html.classList.add('nav-open');
    this.$layer = document.createElement('div');
    this.$layer.setAttribute('id', 'bodyClick');

    if (html.getElementsByTagName('body')) {
      document.getElementsByTagName('body')[0].appendChild(this.$layer);
    }
    const $toggle = document.getElementsByClassName('navbar-toggler')[0];
    this.$layer.onclick = function () {
      // asign a function
      html.classList.remove('nav-open');
      setTimeout(() => {
        this.$layer.remove();
        $toggle.classList.remove('toggled');
      }, 400);
      const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];

      if (window.innerWidth > 0) {
        setTimeout(() => {
          mainPanel.style.position = '';
        }, 500);
      }
    }.bind(this);

    html.classList.add('nav-open');
  }

  sidebarClose() {
    const toggleButton = this.navbarToggler[0];
    const html = document.getElementsByTagName('html')[0];
    toggleButton?.classList.remove('toggled');
    const body = <HTMLElement>document.getElementsByTagName('body')[0];

    if (window.innerWidth > 0) {
      setTimeout(function () {
        body.style.position = '';
      }, 500);
    }
    this.$layer = document.getElementById('bodyClick');
    if (this.$layer) {
      this.$layer.remove();
    }
    html.classList.remove('nav-open');
  }

  searchPlaceListOnClick(place) {
    this.searchPlace = place;
    this.places = [];
  }

  searchPlacesPredictions() {
    if (this.searchPlace !== '') {
      this.mapService.getPlacePredictions(this.searchPlace).subscribe((places_predictions) => {
        this.places = places_predictions;
      });
    } else {
      this.places = [];
    }
  }

  onDateFilterChange(date: Date) {
    const newDate = this.time.getDate(date);
    const selectedDateFilter = this.time
      .moment$(new Date(date))
      .tz(this.time.getClientTimezone())
      .format(Default.format);
    const selectedNextDateFilter = this.time.getTomorrowDate(date);
    this.processSelectedDateFilter(selectedDateFilter);
    this.processSelectedDateFilter(selectedNextDateFilter);
    if (this.previousDateFilter !== newDate) {
      this.store.dispatch(DeliveryActions.setSelectedDateFilter({ selectedDateFilter }));
      this.store.dispatch(DeliveryActions.setSelectedNextDateFilter({ selectedNextDateFilter }));
    }
    this.previousDateFilter = newDate;
  }

  processSelectedDateFilter(selectedDateFilter: string) {
    if (selectedDateFilter === this.dateFilters[DASHBOARD.ORDERS.FILTERS.TODAY]) {
      this.dateFilters.selected = DASHBOARD.ORDERS.FILTERS.TODAY;
    } else if (selectedDateFilter === this.dateFilters[DASHBOARD.ORDERS.FILTERS.YESTERDAY]) {
      this.dateFilters.selected = DASHBOARD.ORDERS.FILTERS.YESTERDAY;
    } else {
      this.dateFilters.selected = '';
    }
  }

  createTask() {
    const pickupUser: BasicUser = {
      uid: this.idUtil.getShortId('cu'),
      name: this.pickupForm.name,
      email: this.pickupForm.email,
      mobile: this.pickupForm.mobile,
    };
    const deliveryUser: BasicUser = {
      uid: this.idUtil.getShortId('cu'),
      name: this.taskForm.value.delivery.name,
      email: this.taskForm.value.delivery.email,
      mobile: this.taskForm.value.delivery.mobile,
    };

    const pickup: Pickup = {
      id: this.idUtil.getShortId('pu'),
      status: 'unassigned',
      customer: pickupUser,
      dateTime: moment(this.taskForm.value.delivery.puDateBefore).format('MMMM Do YYYY, h:mm a'),
      address: {
        lat: '14.5995',
        lng: '120.9842',
        location: this.pickupForm.address,
      },
      items: this.taskForm.value.delivery.items,
    };
    const delivery: Delivery = {
      id: this.idUtil.getShortId('dv'),
      status: 'unassigned',
      customer: deliveryUser,
      dateTime: moment(this.taskForm.value.delivery.dateBefore).format('MMMM Do YYYY, h:mm a'),
      notes: this.taskForm.value.delivery.notes,
      address: {
        lat: '14.5995',
        lng: '120.9842',
        location: this.taskForm.value.delivery.address,
      },
      items: this.taskForm.value.delivery.items,
    };
    const taskForm: Task = {
      delivery: delivery,
      pickup: pickup,
      courier: '',
      type: 'delivery',
    };
  }

  changeDashboardColor(color) {
    const body = document.getElementsByTagName('body')[0];
    if (body && color === 'white-content') {
      body.classList.add(color);
      body.classList.remove('dark-content');
    } else if (body.classList.contains('white-content')) {
      body.classList.remove('white-content');
      body.classList.add('dark-content');
    }
  }

  onChangeDashboardColor(event) {
    const body = document.getElementsByTagName('body')[0];
    if (this.dashboardColor === true) {
      this.changeDashboardColor('dark-content');
    } else {
      this.changeDashboardColor('white-content');
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  onChange(event) {
    const body = document.getElementsByTagName('body')[0];
    if (this.state === true) {
      body.classList.remove('sidebar-mini');
      this.showSidebarMessage('Sidebar mini deactivated...');
    } else {
      body.classList.add('sidebar-mini');
      this.showSidebarMessage('Sidebar mini activated...');
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  showDashboardButtons(url: string) {
    if (url === '/delivery/map' || url === '/delivery/calendar') {
      this.showDashboardButton = true;
    }
    if (url === '/reports') {
      this.showAllBranchesSelector = true;
    }
  }

  calendarComponent() {
    this.ngZone.run(() => this.router.navigate(['delivery/calendar'])).then();
  }

  mapComponent() {
    this.ngZone.run(() => this.router.navigate(['delivery/map'])).then();
  }
}
