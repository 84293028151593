export const TASK_CONSTANT = {
  unassigned: 'orgs/zip/tasks/unassigned/',
};
export const MERCHANT_CONSTANT = {
  newOrder: 'mer-tmf/new/',
  inProgressOrder: 'mer-tmf/inprogress/',
  finishedOrder: 'mer-tmf/finished/',
};

const NEW = 'new';
const INPROGRESS = 'inprogress';
const FINISHED = 'finished';
const CANCELLED = 'cancelled';
const REMOVED = 'removed';

const MERCHANTS = {
  ADVANCEORDERS: 'orderQueued_writes',
  ROOT: 'merchants',
  BRANCHES: 'branches',
  MANAGERS: 'managers',
  AGENTS: 'agents',
  LOCATION: 'location',
  BUILDINGS: 'buildings',
  ORDER: 'order',
  ORDERS: 'orders',
  MANAGEMENT: 'management',
  PRIORITIES: 'priorities',
  SALES: 'sales',
  PAYMENTS: 'payments',
  DELIVERY: 'delivery',
  DELIVERIES: 'deliveries',
  AGGREGATION: 'agg',
  DAILY: 'd',
  WEEKLY: 'w',
  MONTHLY: 'm',
  YEARLY: 'y',
  NEW: 'new',
  INPROGRESS: 'inprogress',
  FINISHED: 'finished',
  REMOVED: 'removed',
  GLORIAFOODID: 'gfz',
};

const STATUS = {
  UNASSIGNED: 'unassigned',
  ASSIGNED: 'assigned',
  ACKNOWLEDGE: 'acknowledge',
  STARTED: 'started',
  INPROGRESS: 'inprogress',
  ARRIVED: 'arrived',
  SUCCESSFUL: 'successful',
  FAILED: 'failed',
  DECLINED: 'declined',
  CANCELLED: 'cancelled',
  REMOVED: 'removed',
  OUTSOURCED: 'outsourced',
};

const STATUS_MAP_STR = [
  'unassigned',
  'assigned',
  'acknowledge',
  'inprogress',
  'arrived',
  'successful',
  'failed',
  'declined',
  'cancelled',
  'removed',
  'outsourced',
];

const STATUS_INT = {
  UNASSIGNED: 0,
  ASSIGNED: 1,
  ACKNOWLEDGE: 2,
  INPROGRESS: 3,
  ARRIVED: 4,
  SUCCESSFUL: 5,
  FAILED: 6,
  DECLINED: 7,
  CANCELLED: 8,
  REMOVED: 9,
  OUTSOURCED: 10,
};

const STATUS_MAP = {
  unassigned: NEW,
  assigned: INPROGRESS,
  acknowledge: INPROGRESS,
  started: INPROGRESS,
  inprogress: INPROGRESS,
  arrived: INPROGRESS,
  successful: FINISHED,
  failed: NEW,
  declined: NEW,
  cancelled: NEW,
  removed: REMOVED,
  outsourced: INPROGRESS,
};

const STATUS_MAP_CATEGORY = [
  NEW,
  INPROGRESS,
  INPROGRESS,
  INPROGRESS,
  INPROGRESS,
  FINISHED,
  NEW,
  NEW,
  NEW,
  REMOVED,
  INPROGRESS,
];

const ORGS = {
  BASE: 'ORGS',
  TASKS: 'tasks',
  AGENTS: 'agents',
};

// Payment status
const PAYMENT_STATUS_MAP = ['pending', 'paid', 'cancelled', 'expired'];
const RETRY = {
  ZERO: 0,
};
const PAYMENT_STATUS = {
  PENDING: 0,
  PAID: 1,
  CANCELLED: 2,
  EXPIRED: 3,
};

const PAYMENT_METHOD_MAP = ['CASH', 'ONLINE'];
const PAYMENT_METHOD = {
  CASH: 0,
  ONLINE: 1,
};

const AGENT_STATUS_STR = ['Available', 'Task Assigned', 'Busy', 'Offline'];

export const RTDB_CONSTANTS = {
  MERCHANTS,
  STATUS,
  STATUS_MAP,
  PAYMENT_STATUS,
  PAYMENT_STATUS_MAP,
  RETRY,
  ORGS,
  PAYMENT_METHOD_MAP,
  PAYMENT_METHOD,
  STATUS_INT,
  STATUS_MAP_CATEGORY,
  STATUS_MAP_STR,
  AGENT_STATUS_STR,
};
