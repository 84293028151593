import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTime',
})
export class GetTimePipe implements PipeTransform {
  transform(dateTime: string): any {
    const dateTimeArr = dateTime.split(',');
    return dateTimeArr[1];
  }
}
