// Constants
import { RTDB_CONSTANTS } from 'src/app/core/constants/rtdb.constants';

// State
import { StoreRootState } from 'src/app/state/state.reducers';
import { createSelector, props } from '@ngrx/store';
import { State } from './delivery.reducer';
import {
  OrdersByCategory,
  OrdersDetails,
  OrdersDetails2,
  RealTimeOrder,
  RealTimeOrders,
} from 'src/app/models/data/order.model';

const MERCHANTS = RTDB_CONSTANTS.MERCHANTS;

// Services
import { ProgressService } from 'src/app/services/utils/status.service';
import { Agent } from 'src/app/models/v2/agent.model';
import * as moment from 'moment';
import { selectDelivery } from '@state/integration/integration.selector';
import { Delivery } from '@models/data/task.model';

export const selectState = (state: StoreRootState) => state.dashboard.delivery;

export const selectRealTimeAgents = createSelector(selectState, (delivery: State) => delivery.realTimeAgents);
export const selectRealTimeAgentsLocation = createSelector(
  selectState,
  (delivery: State) => delivery.realTimeAgents.agents
);

// Agents by Status
export const selectAgentsSpecific = (status: number[]) => {
  return createSelector(selectRealTimeAgents, (realTimeAgents: any) => {
    const agents = realTimeAgents?.agents as Agent[];
    return agents.filter((agent) => status.indexOf(agent.s) !== -1);
  });
};

export const selectAvailableAgents = selectAgentsSpecific([0]);
export const selectBusyAgents = selectAgentsSpecific([1, 2]);
export const selectOfflineAgents = selectAgentsSpecific([3]);
export const selectAssignableAgents = selectAgentsSpecific([0, 1, 2]);

export const selectAgents = createSelector(
  selectAvailableAgents,
  selectBusyAgents,
  selectOfflineAgents,
  selectAssignableAgents,
  (availableAgents: Agent[], busyAgents: Agent[], offlineAgents: Agent[], assignableAgents: Agent[]) => {
    const agentsByStatus = {
      available: availableAgents,
      assignable: assignableAgents,
      busy: busyAgents,
      offline: offlineAgents,
    };
    return agentsByStatus;
  }
);

export const selectAgentList = createSelector(selectState, (delivery: State) => delivery?.realTimeAgents);

// Selected Branch
export const selectSelectedBranch = createSelector(selectState, (delivery: State) => delivery.selectedBranch);
export const selectSelectedBranchLocation = createSelector(
  selectState,
  (delivery: State) => delivery.selectedBranchLocation
);

// Filter
export const selectSelectedDateFilter = createSelector(
  selectState,
  (delivery: State) => delivery.dateFilter.selectedDateFilter
);
export const selectSelectedNextDateFilter = createSelector(
  selectState,
  (delivery: State) => delivery.dateFilter.selectedNextDateFilter
);

export const selectOrdersDetails = createSelector(selectState, (delivery: State) => delivery.ordersDetails);
export const selectOrdersDetails2 = createSelector(selectState, (delivery: State) => delivery.ordersDetails2);

export const selectAgentsDetails = createSelector(selectState, (delivery: State) => delivery.agentsDetails);

// Real Time Deliveries
export const selectRealTimeDeliveries = createSelector(selectState, (delivery: State) => delivery.realTimeDeliveries);

// Real Time Orders
export const seletOrderQueue = createSelector(selectState, (delivery: State) => delivery.orderQueue);
export const selectAssignQueuedOrder = createSelector(selectState, (delivery: State) => delivery.assignQueuedOrder);
export const selectRealTimeOrders = createSelector(selectState, (delivery: State) => delivery.realTimeOrders);

// Sort by Date
export const sortByFulfillDate = (a: RealTimeOrder, b: RealTimeOrder) => {
  const leftDate = moment.utc(a.fulfillAt);
  const rightDate = moment.utc(b.fulfillAt);
  return leftDate.diff(rightDate);
};

// Orders by Category
export const selectOrdersSpecific = (category: string) => {
  return createSelector(selectRealTimeOrders, (realTimeOrders: RealTimeOrders) => {
    const orders = realTimeOrders?.orders as RealTimeOrder[];
    return orders
      .filter((order: RealTimeOrder) => ProgressService.mapToStatus(order.s) === category)
      .sort(sortByFulfillDate);
  });
};

export const selectNewOrders = selectOrdersSpecific(MERCHANTS.NEW);
export const selectInProgressOrders = selectOrdersSpecific(MERCHANTS.INPROGRESS);
export const selectFinishedOrders = selectOrdersSpecific(MERCHANTS.FINISHED);
export const selectRemovedOrders = selectOrdersSpecific(MERCHANTS.REMOVED);

export const selectOrdersByCategory = createSelector(
  selectNewOrders,
  selectInProgressOrders,
  selectFinishedOrders,
  selectRemovedOrders,
  (
    newOrders: RealTimeOrder[],
    inprogressOrders: RealTimeOrder[],
    finishedOrders: RealTimeOrder[],
    removedOrders: RealTimeOrder[]
  ) => {
    const ordersByCategory: OrdersByCategory = {
      new: newOrders,
      inprogress: inprogressOrders,
      finished: finishedOrders,
      removed: removedOrders,
    };
    return ordersByCategory;
  }
);

export const selectOrdersAggregation = createSelector(
  selectState,
  (delivery: State) => delivery.ordersAggregation.orders
);

// Selected Real Time Order
export const selectSelectedRealTimeOrder = createSelector(
  selectState,
  (delivery: State) => delivery.selectedRealTimeOrder
);

export const selectSelectedOrderDetails = createSelector(
  selectState,
  (operation: State) => operation.selectedOrderDetails
);

export const selectOrderDetails = createSelector(
  selectOrdersDetails,
  (ordersDetails: OrdersDetails, orderProps: { id: string }) => ordersDetails.orders[orderProps.id]
);

export const selectOrderDetails2 = createSelector(
  selectOrdersDetails2,
  (ordersDetails: OrdersDetails2, orderProps: { selectedOrders: RealTimeOrder[] }) =>
    ordersDetails.orders.filter((item1) => orderProps.selectedOrders.some((item2) => item1.id === item2.id))
);

export const selectAgentDetails = createSelector(
  selectAgentsDetails,
  (agentDetails, agentProps: { id: string }) => agentDetails.agents[agentProps.id]
);

export const selectOrderActivities = createSelector(selectState, (delivery: State) => delivery.selectedOrderActivities);

export const selectAgentActivities = createSelector(selectState, (delivery: State) => delivery.selectedAgentActivities);

export const selectOrderCalendar = createSelector(selectState, (delivery: State) => delivery.ordersCalendar.orders);

export const selectAdvanceOrders = createSelector(selectState, (delivery: State) => delivery.advanceOrders.orders);

export const selectSelectedAdvanceOrder = createSelector(
  selectState,
  (delivery: State) => delivery.selectedAdvanceOrder.advanceOrder
);

export const selectUpdateOrderStatus = createSelector(selectState, (delivery: State) => delivery?.updateOrderStatus);
export const selectUpdateAgentStatus = createSelector(selectState, (delivery: State) => delivery?.updateAgentStatus);
export const selectUpdateDeliveryAddressStatus = createSelector(
  selectState,
  (delivery: State) => delivery?.updateDeliveryAddressStatus
);
