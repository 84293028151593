import { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { CALENDAR } from 'src/app/core/constants/dashboard.constants';
import * as Time from 'src/app/core/utils/time';
import tippy, { followCursor } from 'tippy.js';
import 'tippy.js/themes/material.css';

@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['event-calendar.component.scss'],
})
export class EventCalendarComponent implements OnInit, OnChanges, AfterViewInit {
  calendarOptions: CalendarOptions = {};

  @Input() eventsSource: any;
  @Input() ordersSource: any;
  @Input() initialDate: Date;

  @Output() outputTimeZone = new EventEmitter<string>();
  @Output() outputMonth = new EventEmitter<number>();
  @Output() outputDateFilter = new EventEmitter<any>();
  @Output() viewFilter = new EventEmitter<any>();

  @ViewChild('calendar', {
    static: false,
  })
  fullcalendar: FullCalendarComponent;

  mode: string;

  tooltipData: string;
  events = [];
  orders = [];
  currentMonth: string;

  // Calendar events
  new = [];
  inProgress = [];
  finished = [];
  future = [];

  calendarApi: any;

  timeZone: string;

  constructor() {}

  ngOnInit() {
    this.mode = CALENDAR.VIEW.ORDERS;
    this.initializeCalendar();
    this.emitTimeZone();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fullcalendar?.getApi()) {
      this.fullcalendar.getApi().render();
      this.fullcalendar.getApi().refetchEvents();
    }
    if (this.mode === CALENDAR.VIEW.SUMMARY) {
      this.aggregatioToEventTransform(this.eventsSource);
    }
    if (this.mode === CALENDAR.VIEW.ORDERS) {
      this.orderToEventTransform(this.ordersSource);
    }
    this.render();
  }

  ngAfterViewInit() {
    if (this.ordersSource) {
      this.orderToEventTransform(this.ordersSource);
      this.render();
    }
    if (this.events) {
      this.aggregatioToEventTransform(this.eventsSource);
      this.render();
    }
    this.fullcalendar.getApi().render();
    this.fullcalendar.getApi().refetchEvents();
  }

  emitTimeZone() {
    this.timeZone = this.calendarOptions.timeZone;
    this.outputTimeZone.emit(this.timeZone);
  }

  emitCurrentView() {
    const month = this.fullcalendar.getApi().view.calendar.getDate().getMonth() + 1;
    const year = this.fullcalendar.getApi().view.calendar.getDate().getFullYear();
    this.currentMonth = `${year}-${month}`;

    this.outputDateFilter.emit({ date: `${year}/${month}`, args: this.mode });
  }

  handleClick(arg) {}

  initializeCalendar() {
    this.calendarOptions = {
      customButtons: {
        previousMonth: {
          text: 'Prev',
          click: this.getEventsByMonthBefore.bind(this),
        },
        nextMonth: {
          text: 'Next',
          click: this.getEventsByMonthAfter.bind(this),
        },
        summary: {
          text: 'Summary',
          click: this.changeViewFilter.bind(this, CALENDAR.VIEW.SUMMARY),
        },
        orders: {
          text: 'Orders',
          click: this.changeViewFilter.bind(this, CALENDAR.VIEW.ORDERS),
        },
        advance: {
          text: 'Advance',
          click: this.changeViewFilter.bind(this, CALENDAR.VIEW.ADVANCE),
        },
      },
      initialDate: this.initialDate,
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'previousMonth,nextMonth today',
        center: 'title',
        right: 'summary,orders,advance',
      },
      events: this.events,
      dayMaxEvents: true,
      timeZone: 'asia/manila',
      height: '100%',
      aspectRatio: 1.5,
      themeSystem: 'material',
      eventDidMount: (info) => {
        if (info?.event?._def?.extendedProps?.details?.address) {
          tippy(info.el, {
            arrow: true,
            theme: 'tomato',
            delay: 0,
            content: info?.event?._def?.extendedProps?.details?.address,
          });
        }
      },
      eventMouseEnter: this.eventHover.bind(this),
      eventClick: this.eventClick.bind(this),
    };
  }

  render() {
    this.fullcalendar?.getApi()?.render();
  }

  eventHover(args: any) {
    if (args.event.extendedProps.details) {
      this.tooltipData = args.event.extendedProps.details;
    }
  }

  eventClick(args: any) {
    if (args.event.extendedProps.details) {
      // TODO: show orders in particular day
    }
  }

  changeViewFilter(args: any) {
    const month = this.fullcalendar.getApi().view.calendar.getDate().getMonth() + 1;
    const year = this.fullcalendar.getApi().view.calendar.getDate().getFullYear();
    const date = `${year}/${month}`;
    this.viewFilter.emit({
      args,
      date,
    });
    this.mode = args;
    if (this.mode === CALENDAR.VIEW.SUMMARY) {
      this.aggregatioToEventTransform(this.eventsSource);
    }
    if (this.mode === CALENDAR.VIEW.ORDERS) {
      this.orderToEventTransform(this.ordersSource);
    }
    if (this.mode === CALENDAR.VIEW.ADVANCE) {
      console.log('Advanced Orders');
    }
    this.render();
  }

  getEventsByMonthBefore() {
    let month = this.fullcalendar.getApi().getDate().getMonth();
    let year = this.fullcalendar.getApi().getDate().getFullYear();
    if (month === 0) {
      month = 12;
      year--;
    }
    const monthString = month > 9 ? `${month}` : `0${month}`;
    this.currentMonth = monthString;
    this.fullcalendar.getApi().changeView('dayGridMonth', `${year}-${monthString}-01`);
    this.fullcalendar.getApi().render();
    this.fullcalendar.getApi().refetchEvents();
    this.emitCurrentView();
  }

  getEventsByMonthAfter() {
    let month = this.fullcalendar.getApi().getDate().getMonth() + 2;
    let year = this.fullcalendar.getApi().getDate().getFullYear();
    if (month === 13) {
      month = 1;
      year++;
    }
    const monthString = month > 9 ? `${month}` : `0${month}`;
    this.currentMonth = monthString;
    this.fullcalendar.getApi().changeView('dayGridMonth', `${year}-${monthString}-01`);
    this.fullcalendar.getApi().render();
    this.fullcalendar.getApi().refetchEvents();
    this.emitCurrentView();
  }

  aggregatioToEventTransform(data: Array<any>) {
    this.events = [];
    data.forEach((value) => {
      Object.entries(value).forEach(([eventkey, eventvalue]) => {
        Object.entries(eventvalue).forEach(([aggregationKey, numberOfAggregation]) => {
          if (
            (aggregationKey === 'new' ||
              aggregationKey === 'inprogress' ||
              aggregationKey === 'finished' ||
              aggregationKey === 'future') &&
            numberOfAggregation
          ) {
            this.events.push({
              id: `${value.dateFilter}/${eventkey}`,
              title: `${aggregationKey} (${numberOfAggregation})`,
              start: this.getISOformat(value.dateFilter, eventkey),
              allDay: true,
            });
          }
        });
      });
    });
    this.calendarOptions.events = this.events;
  }

  orderToEventTransform(data: Array<any>) {
    this.orders = [];
    data.forEach((value) => {
      Object.entries(value).forEach(([eventkey, eventvalue]) => {
        if (eventkey !== 'dateFilter') {
          Object.entries(eventvalue).forEach(([key, val]) => {
            this.orders.push({
              id: `${value.dateFilter}/${eventkey}`,
              title: `${key}`,
              start: val.acceptedAt,
              details: {
                ...val,
              },
            });
          });
        }
      });
    });
    this.calendarOptions.events = this.orders;
  }

  getISOformat(dateFilter: string, date: string) {
    return Time.moment$(`${dateFilter}/${date}`).toISOString();
  }
}
