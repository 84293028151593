import { Injectable } from '@angular/core';

// Actions
import * as AuthActions from '@state/auth/auth.actions';

// Selectors
import * as fromContent from '@state/content/content.selector';
import * as fromAuth from '@state/auth/auth.selector';

// RxJs / NgRx
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { StoreRootState } from '@state/state.reducers';

// Models
import { Merchant, Links } from '@models/v2/merchant.model';
import { User } from '@models/v2/user.model';
import { Features } from '@models/v2/remoteConfig';

// Constants
import { DASHBOARD } from '@constants/dashboard.constants';

@Injectable({
  providedIn: 'root',
})
export class GlobalDataService {
  // User
  public handledBranchesData: any = [];
  public merchantOwnerId: string;
  public user: any;
  public branches: any = [];
  public hqUid: string;
  public branchUid: string;
  public links: Links;
  public photoUrl: string;
  public featuresSubscriptions: string[];

  // Selector
  public user$: Observable<User>;
  public handledBranchesData$: Observable<any>;
  public merchantSelectedBranch$: Observable<any>;
  public selectedBranch$: Observable<any>;
  public features$: Observable<Features>;

  // Notifier
  public RealTimeLocationNotifier$ = new Subject();
  public RealTimeDeliveriesNotifier$ = new Subject();
  public RealTimeOrderNotifier$ = new Subject();
  public RealTimeAgentNotifier$ = new Subject();
  public RealTimeAggregationNotifier$ = new Subject();
  public SettingsNotifier$ = new Subject();

  constructor(private store: Store<StoreRootState>) {
    this.setSelectors();
    this.getUser();
  }

  setSelectors() {
    this.user$ = this.store.select(fromAuth.selectUser);
    this.handledBranchesData$ = this.store.select(fromAuth.selectUserBranches);
    this.merchantSelectedBranch$ = this.store.select(fromAuth.selectMerchantSelectedBranch);
    this.selectedBranch$ = this.store.select(fromAuth.selectSelectedBranch);
    this.features$ = this.store.select(fromContent.selectContentFeatures);
  }

  getUser() {
    this.user$.subscribe((user: Merchant) => {
      if (user) {
        this.handledBranchesData = [];
        this.handledBranchesData = Object.values(user.branches).map(Object);
        this.user = user;
        this.branches = Object.values(user.branches);
        this.hqUid = this.user.hq;
        this.featuresSubscriptions = user?.subscriptions;
        this.branchUid = user.branches[this.hqUid].id;
        this.links = user.links;
        this.photoUrl = user.photoUrl;
      }
    });
  }

  changeMerchantSelectedBranch(merchantSelectedBranch) {
    this.store.dispatch(AuthActions.setMerchantSelectedBranch({ merchantSelectedBranch }));
  }

  changeSelectedBranch(branchId: string) {
    this.store.dispatch(AuthActions.setSelectedBranch({ selectedBranch: this.user.branches[branchId] }));
  }

  unsubscribeRealtimeLocationNotifier() {
    this.RealTimeLocationNotifier$.next('next');
  }

  unsubscribeRealtimeDeliveriesNotifier() {
    this.RealTimeDeliveriesNotifier$.next('next');
  }

  unsubscribeRealtimeOrderNotifier() {
    this.RealTimeOrderNotifier$.next('next');
  }

  unsubscribeRealtimeAgentNotifier() {
    this.RealTimeAgentNotifier$.next('next');
  }

  unsubscribeRealTimeAggregationNotifier() {
    this.RealTimeAggregationNotifier$.next('next');
  }

  unsubscribeSettingsNotifier() {
    this.SettingsNotifier$.next('next');
  }

  getMerchantOwnerId(user: Merchant) {
    if (user?.role === DASHBOARD.ROLES.OWNER) {
      return user?.uid;
    } else {
      return user?.ownerId;
    }
  }
}
