import { Integration } from 'src/app/models/integration/integration.model';
import { Action, createReducer, on } from '@ngrx/store';
import * as IntegrationActions from './integration.actions';

export interface State extends Integration {
  error: Error;
}

const initialDeliveryPricing = {
  whitelabel: {
    loading: true,
  },
  mrspeedy: {
    loading: true,
  },
  lalamove: {
    loading: true,
  },
  transportify: {
    loading: true,
  },
  grab: {
    loading: true,
  },
  angkas: {
    loading: true,
  },
  zip: {
    loading: true,
  },
};

export const initialState: State = {
  delivery: {
    selected: null,
    pricing: { ...initialDeliveryPricing },
    assigned: {},
    whiteLabel: {},
  },
  order: null,
  payment: null,
  error: null,
  cancelOrderStatus: {
    loading: false,
  },
};

const integrationReducer = createReducer(
  initialState,
  on(IntegrationActions.initializeDeliveryPricing, (state) => ({
    ...state,
    delivery: {
      ...state.delivery,
      pricing: initialDeliveryPricing,
    },
  })),
  on(IntegrationActions.cancelDeliveryOrder, (state) => ({
    ...state,
    cancelOrderStatus: {
      loading: true,
    },
  })),
  on(IntegrationActions.reassignDeliveryOrder, (state) => ({
    ...state,
    cancelOrderStatus: {
      loading: true,
    },
  })),
  on(IntegrationActions.cancelDeliveryOrderSuccess, (state, { result }) => ({
    ...state,
    cancelOrderStatus: {
      loading: false,
      ...result,
    },
  })),
  on(IntegrationActions.cancelDeliveryOrderError, (state, { error }) => ({
    ...state,
    cancelOrderStatus: {
      loading: false,
      ...error,
    },
  })),
  on(IntegrationActions.removeDeliveryOrder, (state) => state),
  on(IntegrationActions.getDeliveryCourier, (state) => state),
  on(IntegrationActions.getDeliveryLocation, (state) => state),
  on(IntegrationActions.setDeliveryCourier, (state, { courier }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      pricing: {
        ...state.delivery.pricing,
        ...courier,
      },
    },
    error: null,
  })),
  on(IntegrationActions.getDeliveryPricing, (state) => state),
  on(IntegrationActions.setDeliveryPricing, (state, { pricing }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      pricing: {
        ...state.delivery.pricing,
        ...pricing,
      },
    },
    error: null,
  })),
  on(IntegrationActions.setSelectedDeliveryPlatform, (state, { platform }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      selected: platform,
    },
  })),
  on(IntegrationActions.assignDeliveryPlatform, (state, { orders, platform }) => ({
    ...state,
    response: {
      orderId: orders.map((order: any) => order?.id).toString(),
      ...platform,
      loading: true,
    },
  })),
  on(IntegrationActions.assignWhiteLabelDeliveryPlatformInDelivery, (state) => state),
  on(IntegrationActions.assignWhiteLabelDeliveryPlatformInOperation, (state) => state),
  on(IntegrationActions.getWhiteLabelAgents, (state) => state),
  on(IntegrationActions.setWhiteLabelAgents, (state, { agents }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      whiteLabel: {
        ...state.delivery.whiteLabel,
        agents,
      },
    },
    error: null,
  })),
  on(IntegrationActions.setDeliveryDetails, (state, { deliveryOrder, orderId }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      assigned: {
        ...state.delivery.assigned,
        [orderId]: deliveryOrder,
      },
    },
  })),
  on(IntegrationActions.assignWhiteLabelDeliveryPlatformInDelivery, (state) => ({
    ...state,
    delivery: {
      ...state.delivery,
      whiteLabel: {
        manual: {
          loading: true,
        },
      },
    },
  })),
  on(IntegrationActions.multiDropOffAssignWhiteLabelDeliveryPlatformInDelivery, (state) => ({
    ...state,
    delivery: {
      ...state.delivery,
      whiteLabel: {
        manual: {
          loading: true,
        },
      },
    },
  })),
  on(IntegrationActions.autoAssignWhiteLabelDeliveryPlatformInDelivery, (state) => ({
    ...state,
    delivery: {
      ...state.delivery,
      whiteLabel: {
        auto: {
          loading: true,
        },
      },
    },
  })),
  on(IntegrationActions.setWhiteLabelDeliveryDetails, (state, { message }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      whiteLabel: {
        ...state.delivery.whiteLabel,
        manual: {
          message,
          loading: false,
        },
      },
    },
  })),
  on(IntegrationActions.setWhiteLabelAutoAllocationDeliveryDetails, (state, { message }) => ({
    ...state,
    delivery: {
      ...state.delivery,
      whiteLabel: {
        ...state.delivery.whiteLabel,
        auto: {
          message,
          loading: false,
        },
      },
    },
  })),
  on(IntegrationActions.updateOrderDetails, (state) => state),
  on(IntegrationActions.reset, () => ({ ...initialState })),
  on(IntegrationActions.success, (state, { response }) => ({
    ...state,
    response: {
      ...response,
      loading: false,
    },
  })),
  on(IntegrationActions.error, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return integrationReducer(state, action);
}
