import { Router } from '@angular/router';
import { Component, OnInit, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { StoreRootState } from 'src/app/state/state.reducers';
import * as fromAuth from 'src/app/state/auth/auth.selector';
import { Merchant } from 'src/app/models/data/merchant.model';
import { Observable } from 'rxjs';

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
// Menu Items

export let NAVROUTES: RouteInfo[] = [];

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'sub',
    icontype: 'tim-icons icon-notes',
    rtlTitle: 'لوحة القيادة',
    children: [
      {
        path: 'operation',
        rtlTitle: ' أشكال منتظمة',
        rtlSmallTitle: 'ص ',
        title: 'Operation',
        type: 'link',
        smallTitle: 'OP',
      },
      {
        path: 'management',
        rtlTitle: ' أشكال موسعة',
        rtlSmallTitle: ' هوو',
        title: 'Management',
        type: 'link',
        smallTitle: 'MA',
      },
    ],
  },
  {
    path: '/delivery',
    title: 'Delivery',
    type: 'link',
    // TODO: RTL
    rtlTitle: '',
    icontype: 'tim-icons icon-delivery-fast',
  },
  {
    path: '/reports',
    title: 'Reports',
    type: 'link',
    // TODO: RTL
    rtlTitle: '',
    icontype: 'tim-icons icon-chart-bar-32',
  },
  {
    path: '/settings',
    title: 'Settings',
    type: 'link',
    rtlTitle: 'الحاجيات',
    icontype: 'tim-icons icon-settings',
  },
];

export const ROUTESMERCHANTSDELIVERY: RouteInfo[] = [
  // {
  //   path: '/dashboard',
  //   title: 'Dashboard',
  //   type: 'sub',
  //   icontype: 'tim-icons icon-notes',
  //   rtlTitle: 'لوحة القيادة',
  // },
  {
    path: '/delivery',
    title: 'Delivery',
    type: 'link',
    // TODO: RTL
    rtlTitle: '',
    icontype: 'tim-icons icon-delivery-fast',
  },
  {
    path: '/reports',
    title: 'Reports',
    type: 'link',
    rtlTitle: 'الرسوم البيانية',
    icontype: 'tim-icons icon-chart-bar-32',
  },
  {
    path: '/settings',
    title: 'Settings',
    type: 'link',
    rtlTitle: 'الحاجيات',
    icontype: 'tim-icons icon-settings',
  },
];

export const ROUTESIOT: RouteInfo[] = [
  {
    path: '/iot/dashboard',
    title: 'IOT',
    type: 'link',
    rtlTitle: 'الرسوم البيانية',
    icontype: 'tim-icons icon-bulb-63',
  },
  {
    path: '/iot/devices',
    title: 'Devices',
    type: 'link',
    rtlTitle: 'الرسوم البيانية',
    icontype: 'tim-icons icon-app',
  },
  {
    path: '/settings',
    title: 'Settings',
    type: 'link',
    rtlTitle: 'الحاجيات',
    icontype: 'tim-icons icon-settings',
  },
];

export const ROUTESMERCHANTSIOT: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    type: 'sub',
    icontype: 'tim-icons icon-notes',
    rtlTitle: 'لوحة القيادة',
    children: [
      {
        path: 'operation',
        rtlTitle: ' أشكال منتظمة',
        rtlSmallTitle: 'ص ',
        title: 'Operation',
        type: 'link',
        smallTitle: 'OP',
      },
      {
        path: 'management',
        rtlTitle: ' أشكال موسعة',
        rtlSmallTitle: ' هوو',
        title: 'Management',
        type: 'link',
        smallTitle: 'MA',
      },
    ],
  },
  {
    path: '/delivery',
    title: 'Reports',
    type: 'link',
    // TODO: RTL
    rtlTitle: '',
    icontype: 'tim-icons icon-delivery-fast',
  },
  {
    path: '/reports',
    title: 'Reports',
    type: 'link',
    rtlTitle: 'الرسوم البيانية',
    icontype: 'tim-icons icon-chart-bar-32',
  },
  {
    path: '/iot/dashboard',
    title: 'IOT',
    type: 'link',
    rtlTitle: 'الرسوم البيانية',
    icontype: 'tim-icons icon-bulb-63',
  },
  {
    path: '/iot/devices',
    title: 'Devices',
    type: 'link',
    rtlTitle: 'الرسوم البيانية',
    icontype: 'tim-icons icon-app',
  },
  {
    path: '/settings',
    title: 'Settings',
    type: 'link',
    rtlTitle: 'الحاجيات',
    icontype: 'tim-icons icon-settings',
  },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  auth$: Observable<any>;

  constructor(private store: Store<StoreRootState>, private router: Router, private ngZone: NgZone) {}

  ngOnInit() {
    this.auth$ = this.store.select(fromAuth.selectUser);
    this.auth$.subscribe((user: Merchant) => {
      if (user) {
        if (user.type.find((x) => x === 'merchant') && user.type.find((x) => x === 'iot')) {
          NAVROUTES = ROUTESMERCHANTSIOT;
          return (this.menuItems = ROUTESMERCHANTSIOT.filter((menuItem) => menuItem));
        } else if (user.type.find((x) => x === 'iot') || user.type.find((x) => x === 'school')) {
          NAVROUTES = ROUTESIOT;
          return (this.menuItems = ROUTESIOT.filter((menuItem) => menuItem));
        } else if (user.type.find((x) => x === 'merchant') && user.type.find((x) => x === 'delivery')) {
          NAVROUTES = ROUTESMERCHANTSDELIVERY;
          return (this.menuItems = ROUTESMERCHANTSDELIVERY.filter((menuItem) => menuItem));
        } else {
          NAVROUTES = ROUTES;
          return (this.menuItems = ROUTES.filter((menuItem) => menuItem));
        }
      }
    });
  }

  handleLinkClick(path: string, subpath: string) {
    const _path = subpath ? [path, subpath] : [path];
    this.ngZone.run(() => this.router.navigate(_path)).then();
  }
}
