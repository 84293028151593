const DELIVERY = {
  ZIP: 'zip',
  MRSPEEDY: 'mrspeedy',
  LALAMOVE: 'lalamove',
  NOCOURIER: 'nocourier',
};

const ORDER = {
  GLORIAFOOD: 'gloriafood',
  SHOPEE: 'shopee',
  LAZADA: 'lazada',
};

const INTEGRATION = {
  DELIVERY,
  ORDER,
};

const STRUCTURE = {
  WEBMER_STRUCTURE: 'webmer_structure',
};

const OPERATION = {
  FILTERS: {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
  },
};
const MANAGEMENT = {
  SECTIONS: 'section',
};

const DASHBOARD = {
  OPERATION,
  MANAGEMENT,
};

const CONTENT = {
  DEFAULT: 'webmer_default',
  INTEGRATION_ORDER: 'webmer_integration_order',
  INTEGRATION_DELIVERY: 'webmer_integration_delivery',
  DASHBOARD: 'webmer_structure_dashboard_page',
  REPORTS: 'webmer_structure_reports_page',
  IOT: 'webmer_structure_iot_page',
  SETTINGS: 'webmer_structure_settings_page',
  TRANSLATIONS: 'webmer_translations',
  FEATURES: 'features',
};

export const REMOTE_CONFIG = {
  STRUCTURE,
  CONTENT,
  INTEGRATION,
  DASHBOARD,
};
