import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { ApplicationRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { Meta } from '@angular/platform-browser';
// Core
import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

// Constants
import { REMOTE_CONFIG } from 'src/app/core/constants/remote.config.constants';
import { DEFAULT_CONNECTION_TYPE, DEFAULT_CONNECTION_SPEED } from '@constants/global.constants';

// Services
import { AuthService } from './services/firebase/auth.service';
import { RemoteConfigService } from 'src/app/services/firebase/remote.config.service';
import { NotificationService } from 'src/app/services/utils/notification.service';
import swal from 'sweetalert2';
import { DeviceService } from '@utils/device.service';
import { ModalService } from '@shared/modal.shared.service';

// Stores
import { Store, select } from '@ngrx/store';
import { StoreRootState } from './state/state.reducers';
import * as AuthActions from './state/auth/auth.actions';
import * as fromAuth from 'src/app/state/auth/auth.selector';
import * as AggregationActions from './state/aggregation/aggregation.actions';
import * as ContentActions from './state/content/content.actions';
import * as IntegrationActions from './state/integration/integration.actions';
import * as ManagementActions from './state/dashboard/management/management.actions';
import * as OperationActions from './state/dashboard/operation/operation.actions';
import * as ReportsActions from './state/reports/reports.actions';
import * as IOTActions from './state/iot/iot.actions';
import * as SettingsActions from './state/settings/settings.actions';
import * as StructureActions from './state/structure/structure.actions';
import * as DeliveryActions from './state/dashboard/delivery/delivery.actions';

// Models
import { Merchant } from './models/data/merchant.model';
import { PageStructure } from 'src/app/models/structure/page.structure.model';
import { ContentStructure } from 'src/app/models/structure/content.structure.model';
import { merge, fromEvent, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { TimeService } from './services/shared/time.shared.service';
import { Default } from './core/constants/time.constants';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [{ provide: DeviceService }, { provide: ModalService }],
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  texts: any;
  public timeDefaults = Default;

  public loading = true;
  public route = '';

  constructor(
    public authService: AuthService,
    public translate: TranslateService,
    private store: Store<StoreRootState>,
    private remoteConfigService: RemoteConfigService,
    private app: ApplicationRef,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private ngZone: NgZone,
    private notif: NotificationService,
    public time: TimeService,
    private meta: Meta,
    private deviceService: DeviceService,
    private modalService: ModalService
  ) {
    this.initializeTranslate();
    this.initializeRemoteConfig();
    this.initializeTheme();

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.route = event.url === '/' ? 'delivery/map' : event.url;
        this.loading = true;
      }

      if (event instanceof NavigationEnd) {
        this.route = event.url === '/' ? 'delivery/map' : event.url;
        this.loading = false;
      }

      if (event instanceof NavigationError) {
        this.loading = false;
        // Present error to user
        console.log(event.error);
      }
      this.cdr.detectChanges();
    });
  }

  ngOnInit() {
    this.initializeNetworkTranslation();
    this.initializeModalTranslations();
    this.dispatchUser();
    this.checkUserType();
    this.subscribeToUser();
    this.initializeSettings();
    this.subscribeToNetworkChecker();
  }

  initializeNetworkTranslation() {
    this.translate.get('NETWORK').subscribe((texts) => {
      this.texts = texts;
    });
  }

  initializeModalTranslations() {
    this.translate.get('DASHBOARD.modal').subscribe(() => {
      this.checkUserDevice();
    });
  }

  // check network connection
  checkNetwork$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  subscribeToNetworkChecker() {
    this.checkNetwork$().subscribe((isOnline: boolean) => {
      if (isOnline) {
        const connection =
          (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;
        connection.addEventListener('change', this.updateConnectionStatus(connection));
      } else {
        this.displayDisconnectedNetworkMessage();
      }
    });
  }

  checkUserDevice() {
    if (this.deviceService.IsMobile() || this.deviceService.isTablet()) {
      this.forceDesktopView();
    }
  }

  updateConnectionStatus(connection) {
    if (connection.effectiveType === DEFAULT_CONNECTION_TYPE && connection.downlink >= DEFAULT_CONNECTION_SPEED) {
      return this.notif.showSuccess('You are connected to the internet.');
    } else {
      return this.notif.showWarning('You have a slow internet connection.');
    }
  }

  forceDesktopView() {
    this.modalService.createForceDesktopViewModal().then(() => {
      this.meta.addTags([{ name: 'viewport', content: 'width=1024' }]);
    });
  }

  displayDisconnectedNetworkMessage() {
    swal.fire({
      title: this.texts?.network?.disconnected?.title,
      text: this.texts?.network?.disconnected?.message,
      icon: 'error',
      buttonsStyling: true,
    });
  }

  displayFailedToConnectToInternetMessage() {
    swal
      .fire({
        title: this.texts?.network?.failed?.title || `Failed to connect to internet.`,
        text: this.texts?.network?.failed?.message || `Can't connect to the network.`,
        icon: 'error',
        buttonsStyling: true,
      })
      .then(() => window.location.reload());
  }

  initializeSettings() {
    this.store.select(fromAuth.selectUser).subscribe((user: Merchant) => {
      if (user) {
        this.time.setLocaleAndTz(user.tz || this.timeDefaults.timeZone, this.timeDefaults.lang);
        this.store.dispatch(SettingsActions.getBranchesSettings());
      } else {
        this.time.setLocaleAndTz(this.timeDefaults.timeZone, this.timeDefaults.lang);
      }
    });
  }

  initializeTheme() {
    this.authService.userDoc$.subscribe((userDoc: Merchant) => {
      if (userDoc) {
        const defaults = userDoc?.defaults;
        this.changeThemeColor(defaults?.darkMode);
      }
    });
  }

  initializeTranslate() {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  dispatchUser() {
    this.store.dispatch(AuthActions.getUser());
  }

  subscribeToUser() {
    this.authService.user$.subscribe((user) => {
      if (user) {
        this.getRemoteConfigs();
      } else {
        this.resetState();
      }
    });
  }

  checkUserType() {
    this.authService.userDoc$.subscribe((userDoc: Merchant) => {
      if (userDoc) {
        if (userDoc.type.find((x) => x === 'merchant') && userDoc.type.find((x) => x === 'iot')) {
          this.ngZone.run(() => this.router.navigate(['delivery/map'])).then();
        } else if (userDoc.type.find((x) => x === 'merchant') && userDoc.type.find((x) => x === 'delivery')) {
          this.ngZone.run(() => this.router.navigate([this.route])).then();
        } else if (userDoc.type.find((x) => x === 'iot') || userDoc.type.find((x) => x === 'school')) {
          this.ngZone.run(() => this.router.navigate(['iot/dashboard'])).then();
        } else {
          this.ngZone.run(() => this.router.navigate(['delivery/map'])).then();
        }
      }
    });
  }

  resetState() {
    this.store.dispatch(AggregationActions.reset());
    this.store.dispatch(ContentActions.reset());
    this.store.dispatch(IntegrationActions.reset());
    this.store.dispatch(ManagementActions.reset());
    this.store.dispatch(OperationActions.reset());
    this.store.dispatch(ReportsActions.reset());
    this.store.dispatch(IOTActions.reset());
    this.store.dispatch(SettingsActions.reset());
    this.store.dispatch(StructureActions.reset());
    this.store.dispatch(DeliveryActions.reset());
    this.loading = false;
  }

  getRemoteConfigs() {
    this.remoteConfigService
      .fetchAndActivate()
      .then(() => {
        this.loading = true;
        const structure: PageStructure = JSON.parse(
          this.remoteConfigService.getString(REMOTE_CONFIG.STRUCTURE.WEBMER_STRUCTURE)
        );
        const content: ContentStructure = this.processRemoteContentStructure(structure);

        this.store.dispatch(StructureActions.setPageStructure({ structure }));
        this.store.dispatch(ContentActions.setContent({ content }));
      })
      .catch((err) => {
        this.displayFailedToConnectToInternetMessage();
        this.resetState();
        console.log('Remote Config Error', err);
      })
      .finally(() => {
        this.loading = false;
        this.app.tick();
      });
  }

  processRemoteContentStructure(structure: PageStructure) {
    const defaults = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.DEFAULT));
    const dashboard = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.DASHBOARD));
    const delivery = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.INTEGRATION_DELIVERY));
    const order = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.INTEGRATION_ORDER));
    const reports = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.REPORTS));
    const iot = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.IOT));
    const settings = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.SETTINGS));
    const translations = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.TRANSLATIONS));
    const features = JSON.parse(this.remoteConfigService.getString(REMOTE_CONFIG.CONTENT.FEATURES));
    const integration = {
      delivery,
      order,
    };

    return {
      defaults,
      dashboard,
      integration,
      reports,
      iot,
      settings,
      translations,
      features,
    };
  }

  initializeRemoteConfig() {
    this.remoteConfigService
      .ensureInitialized()
      .then(() => {
        console.log('Firebase Remote Config is initialized');
      })
      .catch((err) => {
        console.error('Firebase Remote Config failed to initialize', err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  changeThemeColor(darkMode: boolean) {
    const body = document.getElementsByTagName('body')[0];
    const dark = 'dark-content';
    const light = 'white-content';
    if (darkMode) {
      body.classList.add(dark);
      body.classList.remove(light);
    } else {
      body.classList.add(light);
      body.classList.remove(dark);
    }
  }
}
