import { Injectable } from '@angular/core';
import { CloudFunctionsService } from '@firebase/cloud.functions.services';
import { DeliveryDetails } from '@models/integration/delivery.model';
import { forkJoin, of, from } from 'rxjs';

import { OrderDetails, RealTimeOrder } from 'src/app/models/data/order.model';
import { Platform } from 'src/app/models/data/platform.model';

import { LalamoveService } from 'src/app/services/integration/delivery/lalamove.service';
import { MrspeedyService } from 'src/app/services/integration/delivery/mrspeedy.service';
import { WhiteLabelService } from 'src/app/services/integration/delivery/whitelabel.service';
import { AnalyticsService } from '../../firebase/analytics.service';
import { GrabExpressService } from './grab.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryService {
  private deliveryFunctions = this.cloudFunctionService.delivery();
  constructor(
    private cloudFunctionService: CloudFunctionsService,
    private grabService: GrabExpressService,
    private lalamoveService: LalamoveService,
    private mrspeedyService: MrspeedyService,
    private whitelabelservice: WhiteLabelService,
    private analyticsService: AnalyticsService
  ) {}

  generateQuotes(orderDetails: OrderDetails[], merchantOwnerUid: string) {
    return forkJoin({
      grab: this.grabService.quote(orderDetails, merchantOwnerUid),
      lalamove: this.lalamoveService.quote(orderDetails, merchantOwnerUid),
      mrspeedy: this.mrspeedyService.quote(orderDetails, merchantOwnerUid),
      whitelabel: this.whitelabelservice.quote(orderDetails),
    });
  }

  cancelOrder(platform: string, branchId: string, merchantId: string, orderDetails: RealTimeOrder) {
    const courierOrderId = orderDetails.cOId;
    this.analyticsService.logEvent('cancel_order', {
      assigned_platform: platform,
      courier_order_id: courierOrderId,
    });
    return from(this[`${platform}Service`].cancel(branchId, merchantId, orderDetails, courierOrderId) as Promise<any>);
  }

  assignPlatform(platform: Platform, orderDetails: OrderDetails[], platformPricing, merchantOwnerUid: any) {
    this.analyticsService.logEvent('assign_platform', {
      assigned_platform: platform.id,
      merchant: orderDetails[0]?.pickup?.customer?.name,
    });
    return from(this[`${platform.id}Service`].assign(orderDetails, platformPricing, merchantOwnerUid) as Promise<any>);
  }

  assignWhiteLabelPlatform(agentId: string, branchId: string, orderDetails: OrderDetails[], merchantId: any) {
    this.analyticsService.logEvent('assign_platform', {
      assigned_platform: 'white-label',
      merchants: [...orderDetails.map((order) => order.pickup?.customer?.name)],
    });
    return from(this.whitelabelservice.assign(agentId, branchId, orderDetails, merchantId) as Promise<any>);
  }

  getOrder(platform: string, branchId: string, merchantId: string, orderDetails: RealTimeOrder) {
    const courierOrderId = orderDetails.cOId;
    this.analyticsService.logEvent('get_order_details', {
      assigned_platform: platform,
      courier_order_id: courierOrderId,
    });
    return from(
      this[`${platform}Service`].getOrder(branchId, merchantId, orderDetails, courierOrderId) as Promise<any>
    );
  }

  getCourier(platform: string, branchId: string, merchantId: string, orderDetails: RealTimeOrder) {
    const courierOrderId = orderDetails.cOId;
    this.analyticsService.logEvent('get_courier_details', {
      assigned_platform: platform,
      courier_order_id: courierOrderId,
    });
    return from(
      this[`${platform}Service`].getCourier(branchId, merchantId, orderDetails, courierOrderId) as Promise<any>
    );
  }

  getLocation(platform: string, branchId: string, merchantId: string, orderDetails: RealTimeOrder) {
    const courierOrderId = orderDetails.cOId;
    this.analyticsService.logEvent('get_courier_location', {
      assigned_platform: platform,
      courier_order_id: courierOrderId,
    });
    return from(
      this[`${platform}Service`].getLocation(branchId, merchantId, orderDetails, courierOrderId) as Promise<any>
    );
  }

  createDelivery(deliveryDetails: DeliveryDetails, orderId: string, branchId: string, merchantOwnerUid: string) {
    this.analyticsService.logEvent('create_delivery_details', {
      assigned_platform: deliveryDetails.courier,
      order_id: orderId,
      courier_order_id: deliveryDetails.courierOrderId,
    });
    return from(
      this.deliveryFunctions.create({ deliveryDetails, orderId, branchId, merchantOwnerUid }) as Promise<any>
    );
  }
}
