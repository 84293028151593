import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Router from '../../state/state.selector';
import { StoreRootState } from 'src/app/state/state.reducers';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  show = true;

  constructor(private store: Store<StoreRootState>) {}

  ngOnInit() {
    this.store.select(Router.selectUrl).subscribe((route: any) => {
      this.showFooter(route);
    });
  }

  showFooter(url: string) {
    if (url === '/delivery/map' || url === '/delivery/calendar') {
      this.show = false;
    }
  }
}
