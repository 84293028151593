const ORDERS = {
  ACTIONS: {
    ASSIGN: 'assign',
    REFRESH: 'refresh',
    REMOVE: 'remove',
    CREATE: 'create',
    CANCEL: 'cancel',
    COURIER: 'getcourier',
  },
  FILTERS: {
    TODAY: 'today',
    YESTERDAY: 'yesterday',
  },
  PRIORITY: {
    NORMAL: 'normal',
    URGENT: 'urgent',
    CRITICAL: 'critical',
    OVERDUE: 'overdue',
  },
  STATUS: {
    UNASSIGNED: 'unassigned',
  },
};

const ROLES = {
  OWNER: 'owner',
  MANAGER: 'manager',
  ADMINL: 'admin',
};

export const DASHBOARD = {
  ORDERS,
  ROLES,
};

export const AGENT_STATUS_LIST = {
  AVAILABLE: 'available',
  BUSY: 'busy',
  OFFLINE: 'offline',
  ASSIGNABLE: 'assignable',
};

export const CALENDAR = {
  VIEW: {
    SUMMARY: 'summary',
    ORDERS: 'orders',
    ADVANCE: 'advance',
  },
};

export const SUBSCRIPTIONS = {
  whitelabel: 'whitelabel',
};

export const STATUS_MAP_DISPLAY_STR = [
  'unassigned',
  'assigned',
  'acknowledge',
  'in transit',
  'arrived',
  'successful',
  'failed',
  'declined',
  'cancelled',
  'removed',
  'outsourced',
];
