import { Pipe, PipeTransform } from '@angular/core';
// Constants
import { TIME_IN_SECONDS } from '@constants/global.constants';

/**
 *
 * @name [HMS] - HOURS MINUTES SECONDS
 * @description transform seconds to HMS
 */

@Pipe({
  name: 'timeInHMS',
})
export class TimeInHMSPipe implements PipeTransform {
  public HOUR_IN_SECONDS = TIME_IN_SECONDS.HOUR_IN_SECONDS;
  public MINUTE_IN_SECONDS = TIME_IN_SECONDS.MINUTE_IN_SECONDS;

  /**
   *
   * @param seconds {number}
   * @returns delivery duration {string}
   */
  transform(timeAsSeconds: number) {
    const hours = Math.floor(timeAsSeconds / this.HOUR_IN_SECONDS);
    const minutes = Math.floor((timeAsSeconds % this.HOUR_IN_SECONDS) / this.MINUTE_IN_SECONDS);
    const seconds = Math.floor((timeAsSeconds % this.HOUR_IN_SECONDS) % this.MINUTE_IN_SECONDS);

    const _hours = hours ? `${hours} hour/s` : '';
    const _minutes = minutes ? `${minutes} minute/s` : '';
    const _seconds = seconds ? `${seconds} second/s` : '';

    return `${_hours} ${_minutes} ${_seconds}`;
  }
}
