import { ENVIRONMENTS } from '@constants/global.constants';
import { Environment } from './environment.model';

export const environment: Environment = {
  production: false,
  envName: ENVIRONMENTS.STAGE,
  BASE_URL: 'https://stage.merchant.zipeli.com/',
  config: {
    apiKey: 'AIzaSyA124gCPobrcVHHF6jojZRWiMJ7QLso_kE',
    authDomain: 'zippelin-stage.firebaseapp.com',
    databaseURL: 'https://zippelin-stage.firebaseio.com',
    projectId: 'zippelin-stage',
    storageBucket: 'zippelin-stage.appspot.com',
    messagingSenderId: '800766336587',
    appId: '1:800766336587:web:5d9e7402cfb64cf076f7ce',
    measurementId: 'G-ZQX0NRHEF7',
  },
};
